import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { useCallback, useEffect, useState } from 'react';
import { deleteFile, uploadFile } from '../../../utils/files';
import { Layout } from '../../../components/Layout/Layout';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { Stakeholders } from '../../../components/Stakeholders';
import { Decision } from './Decision';
import File from './File';
import { TaskSummary } from '../../components/Summary/TaskSummary/TaskSummary';
import { UploadToCloudIcon } from '../../../components/icons/UploadToCloud';
import { useDropzone } from 'react-dropzone';

const MAX_SIZE = 20_000_000;

type ErrorMessages = {
  [key: string]: string;
};

const ERROR_MESSAGES: ErrorMessages = {
  FILE_TOO_LARGE: 'file-too-large',
  TOO_MANY_FILES: 'too-many-files',
  FILE_INVALID_TYPE: 'file-invalid-type',
};

export function LastOppDokument(props: TaskProps) {
  const defaultDocumentRef = props.task.data?.documentRef;
  const [documentRef, setDocumentRef] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(defaultDocumentRef || undefined);
  const [file, setFile] = useState<any>();
  const { flow, t } = props;
  const { stakeholders } = flow.data;

  async function handleDelete() {
    await deleteFile(documentRef!.id);
    setDocumentRef(undefined);
    setFile(undefined);
  }

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0];
    setFile(acceptedFile);
    const { id, name } = await uploadFile(acceptedFile);
    setDocumentRef({ id, name });
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      accept: {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
      },
      maxSize: MAX_SIZE,
    });

  const [errorMsg, setErrorMsg] = useState<
    | {
        message: string;
        code: string | number;
      }
    | undefined
  >(undefined);

  const onPaste = useCallback(async (e: any) => {
    const file = e.clipboardData.files[0];
    if (file) {
      setFile(file);
      const { id, name } = await uploadFile(file);
      setDocumentRef({ id, name });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('paste', onPaste);
    return () => {
      window.removeEventListener('paste', onPaste);
    };
  }, [onPaste]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      const error = fileRejections[0].errors[0];
      const errorMessage = ERROR_MESSAGES[error.code];
      if (errorMessage) {
        setErrorMsg({ message: t(errorMessage), code: error.code });
      }
    } else {
      setErrorMsg(undefined);
    }
  }, [fileRejections, t]);

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        {file ? (
          <div>
            <File file={file} deleteFile={handleDelete} />
          </div>
        ) : (
          <div className="m-auto flex">
            <div className="gap-4" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="flex cursor-pointer justify-center gap-3 rounded-md border border-dashed border-sea-20 px-7 py-5 text-sea-20">
                <UploadToCloudIcon />
                {isDragActive ? (
                  <span className="my-auto">{t('drop-file-here')}</span>
                ) : (
                  <span className="my-auto">
                    {t('upload-drag-and-drop-or-click')}
                  </span>
                )}
              </div>
              <p className="pt-2 text-right text-xs italic">
                {t(`max-file-amount-1`)}
              </p>
            </div>
          </div>
        )}
        {errorMsg && (
          <div className="text-negative-600 text-xs italic">
            {errorMsg.message}
          </div>
        )}
        <div>
          <DecisionPanel>
            <Stakeholders stakeholders={stakeholders} />
            <TaskSummary />
            <Decision {...props} documentRef={documentRef} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
