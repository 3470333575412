import { useFlowContext } from '../../../../hooks/useFlowContext';
import { TaskProps } from '../../../../types/TaskProps';
import {
  ListItem,
  ListItemType,
} from '../../../../components/_shared/ListItem';
import { formatLoanSummary } from './formatLoanSummary';
import { formatProofOfFinanceSummary } from './formatProofOfFinanceSummary';
import { isFinancingApplication } from '../../../tasks/ManuellBehandling/utils/loanPurposeContextHelper';
import { formatGracePeriodSummary } from './changeLoan/formatGracePeriodSummary';
import { formatDueDateSummary } from './changeLoan/formatDueDateSummary';
import { formatRedemptionPeriodSummary } from './changeLoan/formatRedemptionPeriodSummary';

type SummaryDetailsProps = {
  data: TaskProps['flow']['data'];
  showDetails?: any;
  flowDefinitionId: string;
};
export function SummaryDetails({
  data,
  flowDefinitionId,
  showDetails,
}: SummaryDetailsProps) {
  const { t } = useFlowContext();
  const isProofOfFinance = isFinancingApplication(data);

  const formatSummaryData = () => {
    let type;
    if (isProofOfFinance) {
      type = 'proofOfFinance';
    }
    if (flowDefinitionId === 'change-loan-gracePeriod') {
      type = 'gracePeriod';
    }
    if (flowDefinitionId === 'change-loan-duedate') {
      type = 'dueDate';
    }
    if (flowDefinitionId === 'change-loan-repaymentPeriod') {
      type = 'repaymentPeriod';
    }

    switch (type) {
      case 'proofOfFinance':
        return formatProofOfFinanceSummary({ data });
      case 'gracePeriod':
        return formatGracePeriodSummary({ data });
      case 'dueDate':
        return formatDueDateSummary({ data, t });
      case 'repaymentPeriod':
        return formatRedemptionPeriodSummary({ data, t });
      default:
        return formatLoanSummary({ data, t });
    }
  };

  const formattedValue = formatSummaryData();

  return (
    <div className="mt-4 flex flex-col rounded-md bg-white">
      {showDetails && showDetails}
      <ul className="grid text-xs grid-cols-2">
        {formattedValue.map((item: ListItemType, index: number) => {
          return (
            <ListItem
              key={index}
              item={item}
              styling={`px-4 first:border-t-0  divide-gray-200 ${
                index < 2 ? 'border-t-0' : 'border-t'
              } `}
            />
          );
        })}
      </ul>
    </div>
  );
}
