import { AssessmentCard } from './AssessmentCard';
import { isEven } from '../../../utils/isEven';
import { ManualDecision } from '../../../types/ManualDecision';

type AssessmentViewProps = {
  assessments: ManualDecision[];
};

export function AssessmentView({ assessments }: AssessmentViewProps) {
  if (!assessments) return <div />;

  const sortAssessmentsByTimestamp = assessments.sort(
    (a, b) => Date.parse(b?.timestamp) - Date.parse(a?.timestamp),
  );

  return (
    <div className={'flex w-full flex-col gap-4 pt-8'}>
      {sortAssessmentsByTimestamp.map((assessment, index: number) => {
        return (
          <div
            key={index}
            className={`w-3/6 shadow-lg ${
              isEven(index) ? 'self-start' : 'self-end'
            }`}
          >
            <AssessmentCard
              assessment={assessment}
              key={`assessment-card-nr-${index}`}
              bgColor={`${isEven(index) ? 'bg-grass-90' : 'bg-caution-100'}`}
              senderColor={`${
                isEven(index) ? 'text-grass-20' : 'text-caution-800'
              }`}
              timestampColor={`${
                isEven(index) ? 'text-secondary-200' : 'text-caution-700'
              }`}
            />
          </div>
        );
      })}
    </div>
  );
}
