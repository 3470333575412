import React from 'react';
import { Disbursement, Loan } from '../../../../types/DisbursementTypes';
import { formatMoney } from '../../../../utils/formatMoney';
import { BookOpenIcon } from '../../../../components/icons/BookOpenIcon';
import { HeartHouseIcon } from '../../../../components/icons/HeartHouseIcon';
import { formatAccountNumber } from '../../../../utils/formatAccountNumber';
import { DisbursementControlCardFeeRow } from '../../GjennomforUtbetalingsKontroll/DisbursementControlCard/Components/DisbursementControlCard.FeeRow';

type Props = {
  loan?: Loan;
};

export function ControlCardTableBody({ loan }: Props) {
  if (!loan) {
    return null;
  }
  const disbursements = loan.disbursements;
  const customerPayout = loan.payout;
  const fees = loan.fees;

  return (
    <>
      {disbursements?.map((elem: Disbursement, index: number) => {
        return (
          <tr
            key={`disbursement-${elem?.loanFor}-${index}-${elem.nameOfBank}`}
            className={'h-10 border-b border-gray-300'}
          >
            <td className={'flex h-10 items-center gap-x-2 pl-4'}>
              <HeartHouseIcon />
              {elem?.nameOfBank}
            </td>
            <td className={'pr-2 text-right'}>{elem?.loanNumber}</td>
            <td className={'pr-2 text-right'}>
              {formatMoney(elem?.loanAmount ?? 0)}
            </td>
            <td className={'pr-2 text-right'}>
              {formatAccountNumber(elem?.accountNumber)}
            </td>
            <td className={'pr-2 text-right'}>{elem?.ocr}</td>
            <td className={'pr-2 text-right'}>{elem?.message}</td>
            <td className={'pr-2 text-right'}>
              {formatMoney(elem?.correctedAmount ?? 0)}
            </td>
          </tr>
        );
      })}
      <DisbursementControlCardFeeRow fees={fees} />
      <tr className={'h-10'}>
        <td className={'flex h-10 items-center gap-x-2 pl-4'}>
          <BookOpenIcon /> Kundeutbetaling
        </td>
        <td className={'pr-2 text-right'}>
          {customerPayout?.accountNumber &&
            formatAccountNumber(customerPayout?.accountNumber)}
        </td>
        <td className={'pr-2 text-right'}>
          {formatMoney(customerPayout?.amount ?? 0)}
        </td>
        <td className={'pr-2 text-right'}>
          {formatAccountNumber(customerPayout?.correctedAccountNumber)}
        </td>
        <td className={'pr-2 text-right'}>
          <div />
        </td>
        <td className={'pr-2 text-right'}>{customerPayout?.message}</td>
        <td className={'pr-2 text-right'}>
          {formatMoney(customerPayout?.correctedAmount ?? 0)}
        </td>
      </tr>
    </>
  );
}
