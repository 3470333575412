import { EmailCard } from '../EmailCard/EmailCard';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { Stakeholder } from '../../../types/Stakeholder';
import { Disbursement } from '../../../types/DisbursementTypes';
import { toPlainText } from '@portabletext/react';
import { fetchNextTwoWorkDays } from '../../../utils/fetchNextTwoWorkDays';
import { isCadastre } from '../../../types/LoanOffer';

function splitEveryTwo(str: string) {
  const result = [];
  while (str?.length > 0) {
    result?.push(str?.substring(0, 2));
    str = str?.substring(2);
  }
  return result?.join('.');
}

export function EmailView() {
  const { flow, task } = useFlowContext();
  const flowData = flow?.data;
  const loan = flowData?.loanOffer?.loans?.[0];
  const stakeholders: Stakeholder[] = flowData?.stakeholders;
  const disbursements: Disbursement[] = loan?.disbursements;
  const emailDrafts = task?.context?.emailDrafts;
  const property =
    flowData.properties?.[0] ||
    loan?.securities?.[0]?.property ||
    loan?.securities?.[0]?.properties?.[0];
  const address = property?.address;
  const cadastre = property?.key?.replaceAll('-', '/');
  const municipality = property?.municipalityName?.toLowerCase();
  const municipalityName = municipality
    ? municipality?.charAt(0)?.toUpperCase() + municipality?.slice(1)
    : '';
  const housingCooperativeName = property?.housingCooperativeName;
  const shareNumber = property?.shareNumber;

  const housingSituation = () => {
    return isCadastre(property)
      ? `${cadastre} i ${municipalityName} kommune.`
      : `Andel ${shareNumber} i ${housingCooperativeName}.`;
  };

  const nextWorkDays = fetchNextTwoWorkDays().join(' og ');

  const lenders = stakeholders
    ?.map((stakeholder) => {
      return `${stakeholder?.fullName}, født: ${splitEveryTwo(
        stakeholder?.nationalId?.substring(0, 6),
      )}`;
    })
    ?.join('\n');

  const emails = (disbursements || []).map((disbursement, index) => {
    const data: any = {
      lenders,
      nextWorkDays,
      address,
      housingSituation: housingSituation(),
    };
    const emailIndex = index === 0 ? 0 : 1;
    return {
      title: `Epost-utkast ${disbursement?.nameOfBank}`,
      email: toPlainText(emailDrafts?.[emailIndex]?.body ?? '')?.replace(
        /#\{([^}]+)\}/g,
        (match, key: any) => {
          return data[key];
        },
      ),
    };
  });

  return (
    <div className={'grid grid-cols-2 gap-6'}>
      {emails?.map(
        (draft, index) =>
          draft?.email && (
            <EmailCard data={draft} key={`email-card-nr-${index}`} />
          ),
      )}
    </div>
  );
}
