import axios from 'axios';
import { TaskProps } from '../../../types/TaskProps';
import { MaliciousContentMessage } from '../../../components/_shared/MaliciousContentMessage';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@flow/icons';

async function getFile({ task }: TaskProps) {
  try {
    const { mimetype, attachmentKey } = task.context?.attachmentMeta || {};
    const { flowId } = task.variables;
    const { data } = await axios.get(
      `/api/flow/flows/${flowId}/attachments/${attachmentKey}/download`,
      {
        responseType: 'blob',
      },
    );
    const file = new Blob([data], { type: mimetype });

    return URL.createObjectURL(file);
  } catch (err: any) {
    console.error('error', err);
  }

  return null;
}

export function AdditionalFilePreview(props: TaskProps) {
  const { data: src, isLoading } = useQuery({
    queryKey: ['file', props],
    queryFn: () => getFile(props),
  });

  if (!src) {
    if (props.task.context?.cloudmersiveErrors) {
      const { cloudmersiveErrors } = props.task.context;
      return (
        <div className="p-4">
          {cloudmersiveErrors &&
            cloudmersiveErrors.map((error: any) => {
              return <MaliciousContentMessage message={error.message} />;
            })}
        </div>
      );
    }
    return (
      <div className="grid place-items-center h-full">
        {isLoading && <Spinner size={50} />}
      </div>
    );
  }

  if (
    props.task.context?.attachmentMeta?.filetype === 'pdf' ||
    props.task.context?.attachmentMeta?.filetype === 'application/pdf'
  ) {
    return <iframe title="file" src={src} className="h-full w-full" />;
  }

  return (
    <div className="grid h-full w-full items-center justify-center object-fill">
      <img src={src} alt="previewed document" />
    </div>
  );
}
