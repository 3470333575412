import { useFlowContext } from '../../../hooks/useFlowContext';
import { ListItem } from '../../../components/_shared/ListItem';
import React from 'react';

export function CheckListView() {
  const { t, task } = useFlowContext();
  const {
    context: {
      checklistFinancingBridging,
      checklistFinancingMain,
      checklistRefinancing,
    },
  } = task;

  const mainLoanCheckList = constructChecklistObjectToArray(
    checklistFinancingMain,
    t,
  );
  const bridgingLoanCheckList = constructChecklistObjectToArray(
    checklistFinancingBridging,
    t,
  );

  const refinancingCheckList = constructChecklistObjectToArray(
    checklistRefinancing,
    t,
    'refinancing',
  );

  return (
    <div className="mt-4 grid gap-6 rounded-md bg-white">
      {checklistFinancingMain && (
        <div className="grid gap-4">
          <h2 className="text-sm font-semibold text-black">
            {t('control-routine-purchase', 'Kontrollrutine - kjøpet')}
          </h2>
          <ul className="grid grid-cols-1 text-xs">
            {mainLoanCheckList.map((elem, index) => {
              return (
                <ListItem
                  key={index}
                  item={elem}
                  styling={`px-4 first:border-t-0  divide-gray-200 ${
                    index < 1 ? 'border-t-0' : 'border-t'
                  } `}
                />
              );
            })}
          </ul>
        </div>
      )}
      {checklistFinancingBridging && (
        <div className="grid gap-4">
          <h2 className="text-sm font-semibold text-black">
            {t('control-routine-sale', 'Kontrollrutine - salget')}
          </h2>
          <ul className="grid grid-cols-1 text-xs ">
            {bridgingLoanCheckList.map((elem, index) => {
              return (
                <ListItem
                  key={index}
                  item={elem}
                  styling={`px-4 first:border-t-0  divide-gray-200 ${
                    index < 1 ? 'border-t-0' : 'border-t'
                  } `}
                />
              );
            })}
          </ul>
        </div>
      )}
      {checklistRefinancing && (
        <div className="grid gap-4">
          <h2 className="text-sm font-semibold text-black">
            {t('control-routine', 'Kontrollrutine')}
          </h2>
          <ul className="grid grid-cols-1 text-xs ">
            {refinancingCheckList.map((elem, index) => {
              return (
                <ListItem
                  key={index}
                  item={elem}
                  styling={`px-4 first:border-t-0  divide-gray-200 ${
                    index < 1 ? 'border-t-0' : 'border-t'
                  } `}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

function constructChecklistObjectToArray(value: any, t: any, type = '') {
  const result = [];
  if (!value) {
    return [];
  }

  function fetchValue(val: any) {
    if (type === 'refinancing' && typeof val == 'boolean') {
      return val ? t('yes', 'Ja') : t('no', 'Nei');
    }

    if (typeof val === 'boolean') {
      return val ? t('ok', 'OK') : t('not-applicable', 'Ikke aktuelt');
    }

    return `${val}-option`;
  }

  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      result.push({
        label: t(key),
        value: fetchValue(value[key]),
      });
    }
  }

  return result;
}
