import { TaskProps } from '../../../types/TaskProps';
import { FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '../../../components/_shared/TextArea';
import { DecisionButtonGroup } from '../../components/DecisionButtonGroup';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { SettlementSummary } from './SettlementSummary';
import { RadioSelect } from '../../components/common/RadioSelect';

interface Props {
  save: TaskProps['save'];
  complete: TaskProps['complete'];
  task: TaskProps['task'];
}
export function Decision({ save, complete }: Props) {
  const {
    task,
    flow: { data },
  } = useFlowContext();

  const method = useForm({
    resolver: zodResolver(getFormValidationScheme(data.settlement)),
    defaultValues: {
      internalComment: task?.data?.internalComment ?? '',
      signingFlow: data.settlement?.settlementFlow || 'PAPER',
      decision: false,
    },
  });

  const { handleSubmit } = method;

  const onSubmit = (updatedData: any) => {
    const submitData = {
      ...updatedData,
      signingFlow: data.decision
        ? data.settlement?.settlementFlow
        : updatedData.signingFlow,
      reCheckContractDetails:
        updatedData.decision === 'sendBackToRegisterContractDetails',
      sendNewIntentionMessage:
        updatedData.decision === 'sendNewIntentionMessage',
    };
    complete(submitData);
  };

  return (
    <FormProvider {...method}>
      <form className="grid gap-8 py-8" onSubmit={handleSubmit(onSubmit)}>
        <SettlementSummary />
        <RadioSelect
          id="decision"
          options={[
            'approve',
            'sendBackToRegisterContractDetails',
            'sendNewIntentionMessage',
          ]}
          spanVertically={true}
          className="font-bold items-start text-black"
        />
        <TextArea id="internalComment" label="Intern kommentar" />
        <DecisionButtonGroup save={save} />
      </form>
    </FormProvider>
  );
}

function getFormValidationScheme(settlement: any) {
  return z
    .object({
      internalComment: z.string().optional(),
      signingFlow: z.enum(['PAPER', 'DIGITAL'], {
        required_error: 'signing-flow-required',
        invalid_type_error: 'signing-flow-required',
      }),
      decision: z.string({
        errorMap: (issue, ctx) => ({ message: 'hasToChooseOne' }),
      }),
    })
    .superRefine((input, ctx) => {
      if (
        input.signingFlow !== settlement.settlementFlow &&
        input.decision === 'approve'
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'intentionUpdateNeeded',
          path: ['decision'],
        });
      }

      if (
        input.signingFlow === 'DIGITAL' &&
        settlement.settlementIntent?.intentionStatus !== 'ACCEPTED' &&
        input.decision === 'approve'
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'invalidIntentionState',
          path: ['decision'],
        });
      }
    });
}
