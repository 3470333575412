import { queueNames } from './queueNames';

export const menuStructure = [
  {
    name: 'my-cases',
    elems: [
      queueNames.MANUAL_CASE_MANAGEMENT,
      'property-purchased-takeover-my-cases',
      queueNames.DISBURSEMENT,
      queueNames.OTHER_INQUIRIES,
      queueNames.MY_CASES,
    ],
    isDefaultOpen: true,
  },
  {
    name: queueNames.YELLOW,
  },
  {
    name: 'property-purchased',
    elems: [
      queueNames.PROPERTY_PURCHASED_TAKEOVER,
      queueNames.PROPERTY_PURCHASED,
    ],
  },
  {
    name: queueNames.CREDIT_APPROVAL,
  },
  {
    name: queueNames.GREEN,
  },
  {
    name: queueNames.DISBURSEMENT_CONTROL,
  },
  {
    name: queueNames.REJECTION_APPEAL,
  },
  {
    name: queueNames.PEP_KYC,
  },
  {
    name: queueNames.EMPLOYEE_LOAN,
  },
  {
    name: queueNames.PANTEDOKUMENT,
  },
  {
    name: queueNames.IN_PROGRESS_CASES,
    elems: [
      'manual-case-management-all-caseworkers-queue',
      'disbursement-all-caseworkers-queue',
      queueNames.PROPERTY_PURCHASED_IN_PROGRESS,
      queueNames.OTHER_INQUIRIES_ALL_CASEWORKERS,
    ],
  },

  {
    name: queueNames.CHANGE_LOAN,
  },
  {
    name: queueNames.FAILED_CASES,
  },
  {
    name: queueNames.CONTACT_US,
  },
];
