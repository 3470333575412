import { formatMoney } from '../../../utils/formatMoney';
import { ApplicantIcon } from '../../icons/ApplicantIcon';
import { Card } from '../Card';
import { List } from '../List';
import { DecisionCode, DecisionId, findDecisionCode } from './DecisionsHelper';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { ListItemWithDecisionIconType } from '../ListItemWithDecisionIcon';
import { isSelectedLoan } from '../../../utils/isSelectedLoan';

interface ScoringCardProps {
  applicant: any;
  situation?: any;
  decisions?: any;
}

export function ScoringCardApplicant({
  applicant,
  situation,
  decisions,
}: ScoringCardProps) {
  const {
    t,
    flow: { data },
  } = useFlowContext();
  const inntektOppgitt = applicant?.personalEconomy?.income;
  const inntektSbl =
    applicant?.sbl?.mapped?.incomeData?.incomeAveragesAccepted
      ?.truncatedAverage * 12 ?? 0;

  const inntektValue = `sbl: ${formatMoney(inntektSbl)} / ${formatMoney(
    inntektOppgitt,
  )}`;

  const usedDecisions = (decisions ?? data.decisions).filter(
    (decision: any) => decision.context.owner === applicant?.type,
  );

  const deviationDecisionCode = findDecisionCode(
    usedDecisions,
    DecisionId.MONTHLY_DELTA,
  );

  const usedSituation =
    situation?.situation ?? data.households?.[0].situations?.current?.situation;

  const { debts } = usedSituation;

  const selectedLoan = debts.find(isSelectedLoan);
  const existingLoanAmount = applicant.accounts
    ?.filter((account: any) => account.id !== selectedLoan.id)
    .reduce((acc: number, account: any) => acc + account.loanAmount, 0);

  const items: ListItemWithDecisionIconType[] = [
    {
      label: 'creditScore',
      value: applicant?.creditData?.Scoring?.Score,
      decisionCode: findDecisionCode(
        usedDecisions,
        DecisionId.CREDIT_SCORE_LOW,
        DecisionId.CREDIT_BLOCKED,
        DecisionId.CREDIT_SCORE_OUTSIDE,
      ),
    },
    {
      label: 'age',
      value: applicant?.age,
      decisionCode: findDecisionCode(
        usedDecisions,
        DecisionId.AGE_TOO_OLD,
        DecisionId.RETIREE_AND_AGE,
      ),
    },
    {
      label: 'income',
      value: inntektValue,
      decisionCode: findDecisionCode(
        usedDecisions,
        DecisionId.DEVIATION_INCOME,
      ),
    },
    {
      label: 'incomeVariation',
      value: deviationDecisionCode === DecisionCode.GREEN ? 'no' : 'yes', //needs sbl?
      decisionCode: deviationDecisionCode,
    },
    {
      label: 'workCondition',
      value: applicant?.personalEconomy?.workCondition,
      decisionCode: findDecisionCode(
        usedDecisions,
        DecisionId.NOT_EMPLOYEE,
        DecisionId.NOT_ACCEPTED_WORK_STATUS,
        DecisionId.RETIREE_AND_AGE,
      ),
    },
    {
      label: 'creditScore',
      value: applicant?.creditData?.Scoring?.Score,
      decisionCode: findDecisionCode(
        usedDecisions,
        DecisionId.CREDIT_SCORE_LOW,
        DecisionId.CREDIT_SCORE_PAYMENT_REMARK,
        DecisionId.CREDIT_SCORE_OUTSIDE,
        DecisionId.CREDIT_SCORE_EXTRA_ASSESSMENT,
      ),
    },
    {
      label: 'totalLoanAmountInOpf',
      value: formatMoney(selectedLoan.value + existingLoanAmount),
      decisionCode: findDecisionCode(
        usedDecisions,
        DecisionId.TOTAL_LOAN_TOO_HIGH,
      ),
    },
    {
      label: 'totalInterestBearing',
      value: formatMoney(
        applicant?.creditData?.mappedGjeldsregister?.totalInterestBearing ?? 0,
      ),
      decisionCode: findDecisionCode(
        usedDecisions,
        DecisionId.GJELDSREGISTERET_HIGH_DEBT_AMOUNT,
      ),
    },
    ...(applicant?.type === 'coapplicant'
      ? [
          {
            label: 'duplicatePhoneNumber',
            value: applicant?.phoneNumber,
            decisionCode: findDecisionCode(
              usedDecisions,
              DecisionId.DUPLICATE_PHONE_NUMBERS,
            ),
          },
        ]
      : []),
  ];

  return applicant ? (
    <Card>
      <Card.Header className=" bg-grass-90 font-bold text-grass-20 ">
        <ApplicantIcon />
        <Card.HeaderTitle>{t(applicant?.type)}</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <List items={items} />
      </Card.Content>
    </Card>
  ) : null;
}
