import { Fragment, useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { FlowContext } from '../../../hooks/useFlowContext';
import { TaskProps } from '../../../types/TaskProps';
import { Layout } from '../../../components/Layout/Layout';
import { Decision } from './Decision';
import { ControlCard } from './ControlCard/ControlCard';
import { Stakeholders } from '../../../components/Stakeholders';
import { DecisionPanel } from '../../../components/Layout/DecisionPanel';
import { AssessmentView } from '../../../components/_shared/Assessment/AssessmentView';
import { TinglysingCard } from '../../../components/_shared/TinglysingCard/TinglysingCard';
import { Documentation } from '../../../components/_shared/Documentation/Documentation';
import { initSituationStore } from '../../stores/useSituationStore';
import { SuspenseWrapper } from '../../components/SuspenseWrapper';
import { LazyAgreementRegister } from '../../../components/Insight/AgreementRegister/LazyAgreementRegister';
import { initDisbursementStore } from '../../stores/useDisbursementStore';
import { CheckListView } from './CheckListView';
import { Loan } from '../../../types/DisbursementTypes';
import { TaskSummary } from '../../components/Summary/TaskSummary/TaskSummary';

export function FireOyne(props: TaskProps) {
  const { flow, t, task } = props;
  const { stakeholders, tinglysing } = flow?.data;
  const [note, setNote] = useState('');
  const documentationData = flow.data.documentation?.disbursement ?? [];
  const {
    context: { assessments, loans },
  } = task;

  useEffect(() => {
    initDisbursementStore(task);
  }, [task]);

  const tabs = [
    t('overview'),
    t('documentation'),
    ...(!!tinglysing ? [t('tinglysing', 'Tinglysing')] : []),
    t('sikkerheter', 'Sikkerheter'),
    ...(assessments?.length > 0
      ? [`${t('assessments')} (${assessments?.length ?? 0})`]
      : []),
  ];
  useEffect(() => {
    initSituationStore(flow.data);
  }, [flow.data]);

  useEffect(() => {
    initDisbursementStore(task);
  }, [task]);

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div>
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={`whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                        selected ? 'border-b-2 border-basic-ink' : ''
                      }`}
                    >
                      {tab}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full overflow-auto bg-gray-100 p-4">
              <Tab.Panel className="space-y-12">
                {loans?.map((loan: Loan, index: number) => {
                  return (
                    <ControlCard
                      id={loan.id}
                      key={`loan-id-${loan.product}-${index}`}
                    />
                  );
                })}
              </Tab.Panel>
              <Tab.Panel>
                <Documentation
                  documentationElements={documentationData}
                  note={note}
                  setNote={setNote}
                />
              </Tab.Panel>
              {!!tinglysing && (
                <Tab.Panel>
                  <div className="flex flex-col gap-16">
                    <TinglysingCard tinglysing={flow?.data?.tinglysing} />
                  </div>
                </Tab.Panel>
              )}
              <Tab.Panel className="agreementregister-disabled h-full">
                <SuspenseWrapper>
                  <LazyAgreementRegister {...props} />
                </SuspenseWrapper>
              </Tab.Panel>
              <Tab.Panel>
                <AssessmentView assessments={props.task.context?.assessments} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div>
          <DecisionPanel>
            <Stakeholders stakeholders={stakeholders} />
            <TaskSummary />
            <CheckListView />
            <Decision {...props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
