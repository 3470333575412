import { FEATURES } from '@flow/case-manager';
import { Stakeholder } from '../../types/Stakeholder';
import { format, parseISO } from 'date-fns';
import { DecisionCode } from '../../components/_shared/ScoringCard/DecisionsHelper';

interface SearchField {
  name: string;
  value: string;
  queryOperator?: 'equals' | 'contains';
  process?: string;
  type?: 'text' | 'select' | 'date';
  multiple?: boolean;
  options?: { label: string; value: string }[];
}

const businessStatusTranslations: Record<string, string> = {
  WAITING_FOR_UPLOADS_COAPPLICANT: 'Venter på opplasting medlåntaker',
  WAITING_FOR_UPLOAD_DOCUMENTATION: 'Venter på opplasting av dokument',
  WAITING_FOR_UPLOAD_ACCEPTANCE: 'Venter på godkjenning av dokument',
  WAITING_FOR_MAIN_APPLICANT_ECONOMY: 'Venter på hovedlåntaker økonomi',
  WAITING_FOR_CO_APPLICANT_ECONOMY: 'Venter på medlåntaker økonomi',
  WAITING_FOR_MANUAL_CASE_HANDLING: 'Manuell saksbehandling',
  APPLICATION_SUBMITTED_MAIN_APPLICANT: 'Søknad sendt inn',
  SIGNING_DONE: 'Under utbetaling', // under utbetaling
  SIGNING_DONE_WAITING_FOR_CO_APPLICANT:
    'Signer ferdig - venter på medlåntaker',
  REJECTED: 'Avslått',
  APPEAL: 'Anke',
  // NYE STATUSER
  MANUAL_CASE_HANDLING: 'Manuell saksbehandling',
  APPLICANT_INCOMING: 'Søknad på vei inn', // Should be removed down the line
  APPLICATION_INCOMING: 'Søknad på vei inn',
  ACCEPTED_AUTOMATICALLY: 'Automatisk godkjent',
  ACCEPTED_MANUALLY: 'Manuelt godkjent',
  REJECTED_AUTOMATICALLY: 'Automatisk avslått', // Rejected by process
  REJECTED_MANUALLY: 'Manuelt avslått', // Rejected by caseworker
  WAITING_FOR_DISBURSEMENT: 'Venter på utbetaling',
  DISBURSED: 'Utbetalt',
  WAITING_FOR_MAIN_APPLICANT_GENERIC: 'Venter på hovedlåntaker',
};

const searchFields: SearchField[] = [
  {
    name: 'name',
    value: 'data.stakeholders.fullName',
    queryOperator: 'contains',
  },
  {
    name: 'nationalId',
    value: 'data.stakeholders.nationalId',
    queryOperator: 'contains',
  },
  {
    name: 'phoneNumber',
    value: 'data.stakeholders.phoneNumber',
    queryOperator: 'contains',
  },
  {
    name: 'reserveAccount',
    value: 'data.loanOffer.reserveAccount',
    queryOperator: 'contains',
  },
  {
    name: 'businessStatus',
    value: 'data.businessStatus.status',
    type: 'select',
    options: Object.entries(businessStatusTranslations).map(([key, value]) => ({
      label: value,
      value: key,
    })),
  },
];

const searchResultFields = [
  {
    name: 'Søker(e)',
    value: 'data.stakeholders',
    mapping: (value: Stakeholder[]) =>
      value
        .map((stakeholder) =>
          !!stakeholder.fullName ? stakeholder.fullName : stakeholder.email,
        )
        .join(' & '),
  },
  {
    name: 'Opprettet',
    value: 'createdAt',
    mapping: (date: string) => `${format(parseISO(date), 'dd.MM.yyyy')}`,
  },
  {
    name: 'Søknadsstatus(er)',
    value: 'data.businessStatus',
    mapping: (businessStatuses: any) => {
      if (businessStatuses.length === 0) {
        return 'BUSINESS_STATUS_NOT_SET';
      }
      return businessStatuses
        .filter(
          (businessStatus: any, index: number, self: any) =>
            index ===
            self.findIndex((t: any) => t.status === businessStatus.status),
        )
        .map(
          (businessStatus: any) =>
            businessStatusTranslations[businessStatus.status],
        )
        .join(', ');
    },
  },
  {
    name: 'scoring',
    value: 'data.decisions',
    mapping: (decisions: any) => {
      const hasYellowDecision = decisions.find(
        (decision: any) => decision.code === DecisionCode.YELLOW,
      );
      const hasRedDecision = decisions.find(
        (decision: any) => decision.code === DecisionCode.RED,
      );

      return hasRedDecision
        ? DecisionCode.RED
        : hasYellowDecision
        ? DecisionCode.YELLOW
        : DecisionCode.GREEN;
    },
  },
];

export const searchConfiguration = {
  features: [FEATURES.CaseAssignment, FEATURES.CaseNotes],
  searchFields,
  searchResultFields,
  searchProcesses: [
    'mortgage',
    'change-loan-duedate',
    'change-loan-gracePeriod',
    'change-loan-repaymentPeriod',
    'contact-us',
  ],
  searchQueryView: 'searchQueryView',
};
