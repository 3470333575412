import { StateCreator } from 'zustand';
import {
  Asset,
  AssetType,
  FuelType,
} from '../../components/_shared/AssetsCard/AssetsCard.types';
import { nanoid } from 'nanoid';

const DEFAULT_ANNUAL_DRIVING_DISTANCE = 12_000; // km

export interface AssetSlice {
  assets: Asset[];
  addAsset: (type: AssetType) => void;
  deleteAsset: (asset: Asset) => void;
  updateAssetNew: (asset: Asset, shouldUpdate?: boolean) => void;
  setAssets: (assets: Asset[]) => void;
  getAssets: () => Asset[];
  updateFn?: (data?: any) => void;
  updateAsset: (data: UpdateAsset) => void;
  setUpdateFn: (fn: (data: any) => void) => void;
  updateAssets: (assets: Asset[], shouldUpdate?: boolean) => void;
}

interface UpdateAsset {
  id?: string;
  columnId: string;
  value: any;
  initialValue: any;
  rowId?: any;
}

export const createAssetSlice: StateCreator<AssetSlice, [], [], AssetSlice> = (
  set,
  get,
) => ({
  assets: [],
  addAsset: (type: AssetType) => {
    let asset: Asset = {
      id: `new-${nanoid()}`,
      type: type,
      details: type,
      coopDebt: type === AssetType.SHARED_HOUSING ? 0 : undefined,
      marketValue: 0,
      isNew: true,
      usageAcreage: 0,
    };
    switch (type) {
      case AssetType.OWNED_HOUSING:
      case AssetType.SHARED_HOUSING:
        break;
      case AssetType.VEHICLE:
        asset.fuelType = FuelType.ELECTRIC;
        asset.annualDrivingDistance = DEFAULT_ANNUAL_DRIVING_DISTANCE;
        break;
    }
    set((state) => ({
      assets: [...state.assets, asset],
    }));
    get().updateFn && get().updateFn?.(type);
  },
  deleteAsset: (asset: Asset) => {
    set((state) => ({
      assets: state.assets.filter((a) => a.id !== asset.id),
    }));
    get().updateFn && get().updateFn?.(asset);
  },
  updateAssetNew: (asset: Asset, shouldUpdate = true) => {
    set((state) => ({
      assets: state.assets.map((a) => {
        if (a.id === asset.id) {
          if (a.calculatedCosts !== asset.calculatedCosts) {
            return {
              ...asset,
              calculatedCostsIsOverridden: true,
            };
          }
          return asset;
        }
        return a;
      }),
    }));
    shouldUpdate && get().updateFn && get().updateFn?.();
  },
  setAssets: (assets: Asset[]) => set({ assets }),
  getAssets: () => get().assets,
  setUpdateFn: (fn: (data: any) => void) => set({ updateFn: fn }),
  updateAsset: (assetData: UpdateAsset) => {
    const changedField = assetData.columnId;
    set(({ assets }) => {
      const updatedAssets = assets.map((asset: any) => {
        if (asset.id === assetData.rowId) {
          if (changedField === 'priority' && assetData.value === 1) {
            return {
              ...asset,
              [changedField]: assetData.value,
              priorityMortgageAmount: 0,
            };
          }
          if (changedField === 'buildYear') {
            // Removes buildYear from the asset. Default year 2000 will be used in the calculation
            if (assetData.value === 0) {
              const { buildYear, ...rest } = asset;
              return rest;
            }
            if (!isValidBuildYear(assetData.value)) {
              return asset;
            }
          }
          return { ...asset, [changedField]: assetData.value };
        }
        return asset;
      });

      return {
        assets: updatedAssets,
      };
    });
    if (
      !fieldsShouldNotTriggerCalculation.includes(changedField) &&
      !isNewHousing(assetData.id ?? '', changedField)
    ) {
      get().updateFn && get().updateFn?.();
    }
  },
  updateAssets: (assets: Asset[], shouldUpdate: boolean = true) => {
    set({ assets });
    shouldUpdate && get().updateFn && get().updateFn?.();
  },
});

const fieldsShouldNotTriggerCalculation = [
  'priority',
  'priorityMortgageAmount',
  'collateralValue',
  'isSecurity',
  'assetStatus',
];

function isNewHousing(id: string, changedField: string) {
  return id.startsWith('new') && changedField === 'details';
}

function isValidBuildYear(year: any): boolean {
  if (typeof year !== 'number' || isNaN(year)) {
    return false;
  }
  const currentYear = new Date().getFullYear();
  return year > 0 && year <= currentYear;
}
