import { useFlowContext } from '../../hooks/useFlowContext';

export type ListItemType = {
  label: string;
  value: string | number | boolean | undefined;
  context?: string;
};

export function ListItem({
  item,
  styling,
}: {
  item: ListItemType;
  styling?: string;
}): JSX.Element {
  const { t } = useFlowContext();
  return (
    <li
      className={`flex h-11 place-content-between items-center ${
        styling ?? 'text-sm'
      }`}
    >
      <span className="text-xs">{t(item.label)}</span>
      <div className="flex items-center gap-2.5">
        <span>{item.value ? t(item.value?.toString()) : '-'}</span>
      </div>
    </li>
  );
}
