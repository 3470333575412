import { Card } from '../../../../components/_shared/Card';
import { formatMoney } from '../../../../utils/formatMoney';
import { TrendDownIcon } from '../../../../components/icons/TrendDown';
import { ControlCardTableBody } from './ControlCard.TableBody';
import { ControlCardTableHeader } from './ControlCard.TableHeader';
import { DisbursementControlTable } from '../../../../components/_shared/DisbursementControlTable/DisbursementControlTable';
import { useDisbursementControlStore } from '../../../stores/useDisbursementStore';
import { Loan } from '../../../../types/DisbursementTypes';

type ControlCardProps = {
  id: string;
};

export function ControlCard({ id }: ControlCardProps) {
  const { loans } = useDisbursementControlStore();

  const loanValue = loans
    ? loans?.find((elem: Loan) => elem.id === id)
    : emptyLoanValue;

  return (
    <Card>
      <Card.Header className="justify-between bg-tertiary-50  text-tertiary-800">
        <div className="flex items-center gap-3">
          <TrendDownIcon />
          <Card.HeaderTitle>Utbetalinger</Card.HeaderTitle>
        </div>
        <div className="flex items-center gap-1">
          <span>{formatMoney(loanValue?.amount ?? 0)}</span>
          <span className="text-tertiary-400">NOK</span>
        </div>
      </Card.Header>
      <Card.Content horizontalPadding={false}>
        <DisbursementControlTable>
          <DisbursementControlTable.Header>
            <ControlCardTableHeader />
          </DisbursementControlTable.Header>
          <DisbursementControlTable.Content>
            <ControlCardTableBody loan={loanValue} />
          </DisbursementControlTable.Content>
        </DisbursementControlTable>
      </Card.Content>
    </Card>
  );
}

const emptyLoanValue: Loan = {
  id: `no-loan-state`,
  product: '',
  amount: 0,
  disbursements: [],
  payout: { amount: 0, accountNumber: '' },
  fees: [],
};
