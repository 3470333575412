import React from 'react';
import { Card } from '../Card';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { OfficeIcon } from '../../icons/OfficeIcon';
import { PdfIcon } from '../../icons/PdfIcon';
import { formatDate } from '../../../utils/formatDate';
import { TinglysingProps } from './TinglysingCard.types';

export function TinglysingCard({ tinglysing }: TinglysingProps) {
  const { t } = useFlowContext();

  if (!tinglysing?.registrationStatus) {
    return null;
  }
  const grunnbokUrl = tinglysing?.registrationStatus?.urlGrunnbok?.[0];
  const description =
    tinglysing?.registrationStatus?.resultatTinglysing?.status?.description;
  const statusCode =
    tinglysing?.registrationStatus?.resultatTinglysing?.status?.statusCode;
  const documentStatusTinglysing =
    tinglysing?.registrationStatus?.resultatTinglysing?.status
      ?.documentStatusTinglysing;

  const dokumentAar = documentStatusTinglysing?.dokumentAar;
  const dokumentNr = documentStatusTinglysing?.dokumentNr;
  const embete = documentStatusTinglysing?.embete;
  const rettsstiftelser = documentStatusTinglysing?.rettsstiftelser;
  const foeringsdag = documentStatusTinglysing?.foeringsdag;
  const foeringstidspunkt = documentStatusTinglysing?.foeringstidspunkt;
  const rettstiftelsesNummer =
    rettsstiftelser?.rettstiftelse?.[0]?.rettsstiftelsesnummer;

  return (
    <Card>
      <Card.Header className="justify-between bg-grass-90 text-grass-20 ">
        <div className="flex items-center gap-3">
          <OfficeIcon />
          <Card.HeaderTitle>
            {t('tinglysing-title', 'Tinglysing')}
          </Card.HeaderTitle>
        </div>
        <span>
          {foeringsdag &&
            `${formatDate(
              new Date(Date.parse(foeringsdag ? foeringsdag.toString() : '')),
            )} ${foeringstidspunkt}`}
        </span>
      </Card.Header>
      <Card.Content>
        <div className={'grid grid-cols-2 gap-12'}>
          <ul className="divide-y divide-gray-200">
            <li className={`flex h-11 place-content-between items-center`}>
              <span>{t('document-ref', 'Dokumentreferanse')}</span>
              <span>
                {!!dokumentAar &&
                  !!dokumentNr &&
                  !!rettstiftelsesNummer &&
                  !!embete &&
                  `${dokumentAar}/${dokumentNr}-${rettstiftelsesNummer}/${embete}`}
              </span>
            </li>
            <li className={`flex h-11 place-content-between items-center`}>
              <span>{t('status', 'Status')}</span>
              <span>{statusCode}</span>
            </li>
          </ul>
          <ul className="divide-y divide-gray-200">
            <li className={`flex h-11 place-content-between items-center`}>
              <span>{t('grunnbok-utskrift', 'Grunnboksutskrift')}</span>
              {grunnbokUrl && (
                <a
                  href={grunnbokUrl}
                  target={'_blank'}
                  rel="noreferrer"
                  className="hover:pointer flex gap-2 text-sea-30 hover:underline"
                >
                  {t('go-to', 'Gå til')}
                  <PdfIcon />
                </a>
              )}
            </li>
            <li className={`flex h-11 place-content-between items-center`}>
              <span>{t('description', 'Beskrivelse')}</span>
              <span>{description}</span>
            </li>
          </ul>
        </div>
      </Card.Content>
    </Card>
  );
}
