export function HeartHouseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0
  0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_485_23774)">
        <path
          d="M15.5 7.5L8 0.5L0.5 7.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 8.5V15.5H13.5V8.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9411 7.52985C10.7363 7.33759 10.4924 7.19184 10.2261 7.10257C9.95979 7.0133 9.67733 6.98262 9.39805 7.01262C9.11877 7.04263 8.84928 7.13262 8.608 7.27643C8.36672 7.42024 8.15936 7.61447 8.0001 7.84585C7.84084 7.61447 7.63348 7.42024 7.3922 7.27643C7.15093 7.13262 6.88143 7.04263 6.60215 7.01262C6.32287 6.98262 6.04041 7.0133 5.77409 7.10257C5.50777 7.19184 5.26388 7.33759 5.0591 7.52985C4.88251 7.69907 4.74198 7.90228 4.64598 8.12724C4.54998 8.3522 4.50049 8.59426 4.50049 8.83885C4.50049 9.08343 4.54998 9.32549 4.64598 9.55045C4.74198 9.77541 4.88251 9.97862 5.0591 10.1478L8.0001 12.9998L10.9411 10.1478C11.1177 9.97862 11.2582 9.77541 11.3542 9.55045C11.4502 9.32549 11.4997 9.08343 11.4997 8.83885C11.4997 8.59426 11.4502 8.3522 11.3542 8.12724C11.2582 7.90228 11.1177 7.69907 10.9411 7.52985V7.52985Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_485_23774">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
