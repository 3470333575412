import { TaskProps } from '../../../../../types/TaskProps';
import { EmptyState } from './EmptyState';
import React from 'react';
import { FlowContext } from '../../../../../hooks/useFlowContext';
import { DecisionsCard } from '../../../../../components/_shared/DecisionCard/DecisionCard';
import { EconomyCard } from '../../../../tasks/ManuellBehandling/EconomyCard/EconomyCard';
import { FamilyCard } from '../../../../../components/_shared/FamilyCard/FamilyCard';
import { IncomeCard } from '../../../../../components/_shared/IncomeCard/IncomeCard';
import { DebtCard } from '../../../../../components/_shared/DebtCard/DebtCard';
import { AssetsCard } from '../../../../../components/_shared/AssetsCard/AssetsCard';
import { mapDecisionsData } from '../../../../tasks/ManuellBehandling/EconomyCard/useEconomyState';
import { useDecisionsState } from '../../../../tasks/ManuellBehandling/DecisionsCard/useDecisionsState';

export default function AnalysisTabOriginal(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;

  const situation = data?.households?.[0]?.situations.original.situation;

  const hasSituation =
    !!data?.households?.[0]?.situations?.original?.betjeningsevne;

  const decisions = useDecisionsState(
    data.decisionsOriginal || [],
    data.households?.[0]?.situations?.current,
    data.loans,
  );

  if (!hasSituation) {
    return <EmptyState t={t} tab={t('analysis', 'Analyse')} />;
  }

  const economyItems = mapDecisionsData(
    data.households[0]?.situations?.original?.betjeningsevne,
    data.decisions,
  );

  return (
    <FlowContext.Provider value={props}>
      <div className="min-h-40 space-y-12">
        <div className="grid  grid-cols-2 gap-6">
          {decisions && <DecisionsCard decisions={decisions.decisions} />}
          {<EconomyCard economyItems={economyItems} />}
        </div>
        <FamilyCard persons={situation.persons} editable={false} />
        <IncomeCard incomes={situation.incomes} editable={false} />
        <DebtCard debts={situation.debts} />
        <AssetsCard assets={situation.assets} />
      </div>
    </FlowContext.Provider>
  );
}
