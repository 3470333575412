import { queueNames } from './queueNames';

const PURCHASE_TAKEOVER_DATE_TRESHOLD_DAYS = 14;

const baseQueue = {
  queueSort: 'sort=createdAt&dir=1',
  limit: 20,
  pollingInterval: 30_000,
};
const taskFilterBase = `/?status=pending&taskCategory=user-task`;

const currentTime = new Date();
currentTime.setDate(
  currentTime.getDate() + PURCHASE_TAKEOVER_DATE_TRESHOLD_DAYS,
);
const takeoverDateTreshold = currentTime.toISOString().split('T')[0];

export const queueDefinition = {
  [queueNames.MY_CASES]: {
    name: queueNames.MY_CASES,
    queueType: 'assignee',
    queueFilter: '?status=active&hasIncidents=false',
    ...baseQueue,
  },
  [queueNames.MANUAL_CASE_MANAGEMENT]: {
    name: queueNames.MANUAL_CASE_MANAGEMENT,
    queueType: 'assignee',
    queueFilter: buildQueueFilterString({
      queueName: queueNames.MANUAL_CASE_MANAGEMENT,
      flowDefinitionIds: ['mortgage'],
    }),
    ...baseQueue,
  },
  [queueNames.DISBURSEMENT]: {
    name: queueNames.DISBURSEMENT,
    queueType: 'assignee',
    queueFilter: buildQueueFilterString({
      queueName: queueNames.DISBURSEMENT,
      flowDefinitionIds: ['mortgage'],
    }),
    ...baseQueue,
  },
  [queueNames.YELLOW]: {
    name: queueNames.YELLOW,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.YELLOW,
      flowDefinitionIds: ['mortgage'],

      additionalQueryParams: ['assignee.id=!'],
    }),
    ...baseQueue,
  },
  [queueNames.GREEN]: {
    name: queueNames.GREEN,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.GREEN,
      flowDefinitionIds: ['mortgage'],
      additionalQueryParams: ['assignee.id=!'],
    }),
  },
  [queueNames.CREDIT_APPROVAL]: {
    name: queueNames.CREDIT_APPROVAL,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.CREDIT_APPROVAL,
      flowDefinitionIds: ['mortgage'],
    }),
    ...baseQueue,
  },

  [queueNames.DISBURSEMENT_CONTROL]: {
    name: queueNames.DISBURSEMENT_CONTROL,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.DISBURSEMENT_CONTROL,
      flowDefinitionIds: ['mortgage'],
    }),
    ...baseQueue,
  },
  [queueNames.REJECTION_APPEAL]: {
    name: queueNames.REJECTION_APPEAL,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.REJECTION_APPEAL,
      flowDefinitionIds: ['mortgage'],
    }),
    ...baseQueue,
  },
  [queueNames.PEP_KYC]: {
    name: queueNames.PEP_KYC,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.PEP_KYC,
      flowDefinitionIds: ['mortgage'],
    }),
    ...baseQueue,
  },
  [queueNames.EMPLOYEE_LOAN]: {
    name: queueNames.EMPLOYEE_LOAN,
    queueFilter: buildQueueFilterString({
      //queueName: queueNames.EMPLOYEE_LOAN,
      flowDefinitionIds: ['mortgage'],
      additionalQueryParams: [
        'data.stakeholders.isEmployee=true&assignee.id=!&data.businessStatus.status=!APPLICATION_INCOMING&data.businessStatus.status=!APPLICANT_INCOMING',
      ],
    }),
    ...baseQueue,
  },
  [queueNames.FAILED_CASES]: {
    name: queueNames.FAILED_CASES,
    queueFilter: '?status=active&hasIncidents=true',
    ...baseQueue,
  },
  [queueNames.MANUAL_CASE_MANAGEMENT_ALL_CASEWORKERS]: {
    name: queueNames.MANUAL_CASE_MANAGEMENT_ALL_CASEWORKERS,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.MANUAL_CASE_MANAGEMENT,
      flowDefinitionIds: ['mortgage'],
      additionalQueryParams: ['assignee.id='],
    }),
    ...baseQueue,
  },
  [queueNames.DISBURSEMENT_ALL_CASEWORKERS]: {
    name: queueNames.DISBURSEMENT_ALL_CASEWORKERS,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.DISBURSEMENT,
      flowDefinitionIds: ['mortgage'],

      additionalQueryParams: ['assignee.id='],
    }),
    ...baseQueue,
  },

  [queueNames.PANTEDOKUMENT]: {
    name: queueNames.PANTEDOKUMENT,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.PANTEDOKUMENT,
      flowDefinitionIds: ['mortgage'],
    }),
    ...baseQueue,
  },
  [queueNames.CHANGE_LOAN]: {
    name: queueNames.CHANGE_LOAN,
    queueFilter: buildQueueFilterString({
      flowDefinitionIds: [
        'change-loan-duedate',
        'change-loan-gracePeriod',
        'change-loan-repaymentPeriod',
      ],
      queueName: queueNames.CHANGE_LOAN,
      additionalQueryParams: ['assignee.id=!'],
    }),
    ...baseQueue,
  },
  [queueNames.CONTACT_US]: {
    name: queueNames.CONTACT_US,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.CONTACT_US,
      flowDefinitionIds: ['contact-us'],
      additionalQueryParams: ['assignee.id=!'],
    }),
    taskFilter: buildTaskFilterString(['handter-foresporsel']),
    ...baseQueue,
  },
  [queueNames.OTHER_INQUIRIES]: {
    name: queueNames.OTHER_INQUIRIES,
    queueType: 'assignee',
    queueFilter: `?status=active&hasIncidents=false&flowDefinitionId=change-loan-duedate&flowDefinitionId=change-loan-gracePeriod&flowDefinitionId=change-loan-repaymentPeriod&flowDefinitionId=contact-us`,
    taskFilter: buildTaskFilterString([
      'endre-forfall',
      'endre-avdragsfrihet',
      'endre-nedbetalingstid',
      'handter-foresporsel',
    ]),
    ...baseQueue,
  },
  [queueNames.OTHER_INQUIRIES_ALL_CASEWORKERS]: {
    name: queueNames.OTHER_INQUIRIES_ALL_CASEWORKERS,
    queueFilter: buildQueueFilterString({
      flowDefinitionIds: [
        'change-loan-duedate',
        'change-loan-gracePeriod',
        'change-loan-repaymentPeriod',
        'contact-us',
      ],
      additionalQueryParams: ['assignee.id='],
    }),
    taskFilter: '/?status=pending&taskCategory=user-task',
    ...baseQueue,
  },
  [queueNames.PROPERTY_PURCHASED]: {
    name: queueNames.PROPERTY_PURCHASED,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.PROPERTY_PURCHASED,
      flowDefinitionIds: ['mortgage'],
      additionalQueryParams: ['assignee.id=!'],
    }),
    ...baseQueue,
  },
  [queueNames.PROPERTY_PURCHASED_IN_PROGRESS]: {
    name: queueNames.PROPERTY_PURCHASED_IN_PROGRESS,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.PROPERTY_PURCHASED,
      flowDefinitionIds: ['mortgage'],
      additionalQueryParams: ['assignee.id='],
    }),
    ...baseQueue,
  },

  [queueNames.PROPERTY_PURCHASED_TAKEOVER]: {
    name: queueNames.PROPERTY_PURCHASED_TAKEOVER,
    queueFilter: buildQueueFilterString({
      queueName: queueNames.PROPERTY_PURCHASED,
      flowDefinitionIds: ['mortgage'],
      additionalQueryParams: [
        `assignee.id=!&data.proofOfFinance.purchase.takeoverDate=<${takeoverDateTreshold}`,
      ],
    }),
    ...baseQueue,
    queueSort: `sort=data.proofOfFinance.purchase.takeoverDate&dir=1`,
  },
  'property-purchased-takeover-my-cases': {
    name: 'property-purchased-takeover-my-cases',
    queueType: 'assignee',
    queueFilter: buildQueueFilterString({
      queueName: queueNames.PROPERTY_PURCHASED,
      flowDefinitionIds: ['mortgage'],
      additionalQueryParams: [
        `data.proofOfFinance.purchase.takeoverDate=<${takeoverDateTreshold}`,
      ],
    }),
    ...baseQueue,
    queueSort: `sort=data.proofOfFinance.purchase.takeoverDate&dir=1`,
  },
};

function buildQueueFilterString({
  queueName,
  flowDefinitionIds,
  additionalQueryParams,
}: {
  queueName?: string;
  flowDefinitionIds: string[];
  additionalQueryParams?: string[];
}) {
  const baseQueueFilter = '?status=active&hasIncidents=false';

  let queueFilter = `${baseQueueFilter}&flowDefinitionId=${flowDefinitionIds.join(
    '&flowDefinitionId=',
  )}`;

  queueFilter += queueName ? `&data.queues.${queueName}=true` : '';
  if (additionalQueryParams && additionalQueryParams?.length > 0) {
    queueFilter += `&${additionalQueryParams?.join('&')}`;
  }
  queueFilter += '&view=queueView';

  return queueFilter;
}

function buildTaskFilterString(tasks: string[]) {
  return taskFilterBase + '&taskType=' + tasks.join('&taskType=');
}
