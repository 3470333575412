import { useMemo } from 'react';
import { useFlowContext } from '../../../hooks/useFlowContext';
import { convertToBlob } from '../../../utils/convertToBlob';
import { CrossIcon } from '../../../components/icons/CrossIcon';

export default function File({
  file,
  deleteFile,
}: {
  file: File;
  deleteFile: () => void;
}) {
  const { t } = useFlowContext();
  const isImage = file?.type?.includes('image');
  const blob = useMemo(() => convertToBlob(file), [file]);

  return (
    <>
      {isImage ? (
        <img src={blob} alt="file" className="w-full" />
      ) : (
        <iframe className={'h-screen w-full'} title="file" src={blob} />
      )}
      <div>
        <button
          onClick={deleteFile}
          className="absolute bottom-5 left-5 bg-white border-sea-20 border px-2 py-1 rounded-md text-sea-20 flex gap-2 align-middle"
        >
          <div className="my-auto">
            <CrossIcon height={16} width={16} />
          </div>
          {t('delete-file', 'Slett dokument')}
        </button>
      </div>
    </>
  );
}
