import { PersonIcon } from '../../../components/icons/PersonIcon';
import { Card } from '../../../components/_shared/Card';
import { List } from '../../../components/_shared/List';
import { ListItemWithDecisionIconType } from '../../../components/_shared/ListItemWithDecisionIcon';
import {
  DecisionCode,
  DecisionId,
  findDecisionCode,
} from '../../../components/_shared/ScoringCard/DecisionsHelper';
import { useFlowContext } from '../../../hooks/useFlowContext';

export default function PersonCard({ stakeholder }: { stakeholder: any }) {
  const { flow, t } = useFlowContext();
  const decisions =
    flow?.data?.decisions.filter(
      (decision: any) => decision.context.owner === stakeholder?.type,
    ) || [];

  const bornOutsideOfNorwayDecisionCode = findDecisionCode(
    decisions,
    DecisionId.KYC_BORN_OUTSIDE,
  );
  const foreignResidencyOrTaxResidencyDecisionCode = findDecisionCode(
    decisions,
    DecisionId.KYC_MULTIPLE_CITIZENSHIP,
    DecisionId.PAYS_TAX_TO_ANOTHER_STATE,
  );
  const paymentsOver100kDecisionCode = findDecisionCode(
    decisions,
    DecisionId.KYC_EXPECTING_PAYMENTS_FROM_ABROAD,
  );
  const pepDecisionCode = findDecisionCode(decisions, DecisionId.IS_PEP);

  const items: ListItemWithDecisionIconType[] = [
    {
      label: t('decision-kyc-born-outside'),
      decisionCode: bornOutsideOfNorwayDecisionCode,
      value: bornOutsideOfNorwayDecisionCode === DecisionCode.YELLOW,
    },
    {
      label: t('decision-kyc-multiple-citizenship'),
      decisionCode: foreignResidencyOrTaxResidencyDecisionCode,
      value: foreignResidencyOrTaxResidencyDecisionCode === DecisionCode.YELLOW,
    },
    {
      label: t('decision-kyc-expecting-payments-from-abroad'),
      decisionCode: paymentsOver100kDecisionCode,
      value: paymentsOver100kDecisionCode === DecisionCode.YELLOW,
    },
    {
      label: t('pep'),
      decisionCode: pepDecisionCode,
      value: pepDecisionCode === DecisionCode.YELLOW,
    },
  ];
  return (
    <Card>
      <Card.Header className="justify-between bg-grass-90 text-grass-20">
        <div className="flex gap-3">
          <PersonIcon height={24} width={24} />
          <Card.HeaderTitle>{stakeholder?.fullName}</Card.HeaderTitle>
        </div>
      </Card.Header>
      <Card.Content>
        <List items={items} />
      </Card.Content>
    </Card>
  );
}
