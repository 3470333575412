import { BetjeningsevneIcon } from '../../icons/BetjeningsevneIcon';
import { Card } from '../Card';
import { List } from '../List';
import { ListItemWithDecisionIconType } from '../ListItemWithDecisionIcon';
import { useFlowContext } from '../../../hooks/useFlowContext';

import { DecisionId, findDecisionCode } from './DecisionsHelper';
import { formatMoney } from '../../../utils/formatMoney';
import { calculateTotalIncomeRental } from '../../../customer/tasks/ManuellBehandling/utils/incomesHelper';
import { Asset, HousingType } from '../AssetsCard/AssetsCard.types';
import { useProductStore } from '../../../customer/stores/useProductStore';
import { useSituationStore } from '../../../customer/stores/useSituationStore';

export function ScoringCardOther({ inputSituation }: { inputSituation?: any }) {
  const {
    t,
    flow: { data },
  } = useFlowContext();

  const usedSituation =
    inputSituation ?? data.households?.[0].situations?.current;

  const { situation, betjeningsevne } = usedSituation;

  const storeAssets = useSituationStore((s) => s.assets);

  const assets = situation ? situation?.assets : storeAssets;

  const collateralAssets: Asset[] = assets.filter(
    (asset: Asset) => asset.isSecurity,
  );

  const decisions = situation ? data.decisionsOriginal : data.decisions;

  const propertyValueUserInput = collateralAssets.reduce(
    (acc, asset) => acc + (asset.statedValue ?? 0),
    0,
  );

  const propertyValueEdvEstimate = collateralAssets.reduce(
    (acc, asset) =>
      acc + (asset.fetchedValue ?? asset.eiendomsverdi?.fetchedValue ?? 0),
    0,
  );

  const storeLtvProduct = useProductStore((s) => s.ltvProduct);

  const loanToValue = inputSituation
    ? inputSituation?.ltvProduct?.calculatedLtv
    : storeLtvProduct?.calculatedLtv;

  const boligVerdiDiff = propertyValueUserInput / propertyValueEdvEstimate;

  const mainApplicant = data.stakeholders?.[0];
  const payout = data.loans?.[0]?.redemption?.payout;

  const rentalIncome = formatMoney(
    calculateTotalIncomeRental(situation.incomes),
  );

  const coopDebt = collateralAssets.reduce(
    (acc, asset) => acc + (asset.coopDebt ?? 0),
    0,
  );

  let highestAreaScore = 0;

  if (data.properties) {
    highestAreaScore = Math.max(
      ...(data?.properties || []).map(
        (property: any) => property?.eiendomsverdiData?.areaMarketScore ?? 0,
      )!,
      0,
    )!;
  } else {
    highestAreaScore =
      data?.loans?.[0]?.securities?.[0]?.property?.eiendomsverdiData
        ?.areaMarketScore ?? 0;
  }

  const hasHolidayHomeSecurity = collateralAssets.some(
    (asset) => asset?.housingType === HousingType.CABIN,
  );

  const items: ListItemWithDecisionIconType[] = [
    {
      label: t('loanToValue'),
      value: loanToValue ? `${(loanToValue * 100).toFixed(2)} %` : '-',
      decisionCode: findDecisionCode(decisions, DecisionId.LTV_TOO_HIGH),
    },
    {
      label: t('debtDegree'),
      value: betjeningsevne?.requestedLoan?.debtDegree?.toFixed(2) ?? 0,
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.NOKKELTALL_HIGH_DEBT_DEGREE,
      ),
    },
    {
      label: t('liquidityIndicator'),
      value: betjeningsevne?.requestedLoan?.liquidityIndicator?.toFixed(2) ?? 0,
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.NOKKELTALL_INSOLVENCY,
      ),
    },
    {
      label: t('numLienHolders'),
      value: mainApplicant?.properties[0]?.sumHolderOfLien ?? 0,
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.OBJECT_NUM_LIEN_HOLDER,
      ),
    },
    {
      label: t('propertyValueDeviation'),
      value: boligVerdiDiff ? boligVerdiDiff.toFixed(2) : '-',
      decisionCode: findDecisionCode(decisions, DecisionId.LTV_DIFFERENCE),
    },
    {
      label: t(DecisionId.OTHER_INCOME_RENTAL),
      value: rentalIncome,
      decisionCode: findDecisionCode(decisions, DecisionId.OTHER_INCOME_RENTAL),
    },
    {
      label: t(DecisionId.COOP_DEBT_GREATER_THAN_PROPERTY_VALUE),
      value: formatMoney(coopDebt),
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.COOP_DEBT_GREATER_THAN_PROPERTY_VALUE,
      ),
    },
    {
      label: 'areaScore',
      value: highestAreaScore,
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.COOP_DEBT_GREATER_THAN_PROPERTY_VALUE,
      ),
    },
    {
      label: 'hasHolidayHomeSecurity',
      value: hasHolidayHomeSecurity,
      decisionCode: findDecisionCode(
        decisions,
        DecisionId.OBJECT_OPPLAANING_HAS_HOLIDAY_HOME,
      ),
    },
    ...(payout
      ? [
          {
            label: t('payoutReason'),
            value: payout?.loanFor,
            decisionCode: findDecisionCode(
              decisions,
              DecisionId.PAYOUT_REASON_OTHER,
            ),
          },
        ]
      : []),
  ];

  return (
    <Card>
      <Card.Header className="bg-note-100 font-bold text-note-900">
        <BetjeningsevneIcon />
        <Card.HeaderTitle>{t('other')}</Card.HeaderTitle>
      </Card.Header>
      <Card.Content>
        <List items={items} />
      </Card.Content>
    </Card>
  );
}
